<template>
  <v-container fluid grid-list-lg style="overflow: scroll">
    <v-card outlined class="rounded-xl">
      <v-card-title primary-title>
        <v-layout row wrap align-center class="mb-3">
          <v-flex shrink>
            <v-btn icon outlined @click.stop.prevent="$router.go(-1)">
              <v-icon>mdi-chevron-left</v-icon>
            </v-btn>
          </v-flex>
          <v-flex shrink class="font-weight-bold black--text">
            Bill Payment
          </v-flex>
          <v-spacer></v-spacer>
          <v-flex shrink class="text-right">
            <SearchBar v-model="search" />
          </v-flex>
        </v-layout>
      </v-card-title>
      <v-card-text>
        <v-card flat class="cardTitle" :color="$style.card.background">
          <v-card-text>
            <v-layout row wrap>
              <v-flex shrink>
                <DateTimePicker :range="true" v-model="dateTime" :initStartDate="getStart" :initEndDate="getEnd"
                  @submit="getList()" :defaultRange="defaultRange" />
              </v-flex>
              <!-- <v-flex class="ml-2" shrink> -->
              <!-- <v-select
                hide-details
                item-text="name"
                :items="items"
                return-object
                v-model="selected"
                solo
              ></v-select> -->
              <!-- </v-flex> -->
              <v-spacer></v-spacer>
              <v-flex shrink v-if="
  !$vuetify.breakpoint.xsOnly
">
                <DateSelectorView :dateTime="dateTime" />
              </v-flex>
            </v-layout>
          </v-card-text>
        </v-card>

        <v-data-table class="table-header" hide-default-footer :items-per-page="billPayment.length" :headers="headers"
          :items="billPayment" :loading="loading">
          <template v-slot:item="{ item }">
            <tr class="pointer">
              <td class="text-xs-left small-td">
                {{ item.createdAt | moment('MM/DD/YYYY LT') }}
              </td>
              <td>{{ item.phoneNumber | USPhoneNumber }}</td>
              <td>{{ item.Carrier.name }}</td>
              <td class="text-right">{{ item.amount | currency }}</td>
              <td class="text-right">{{ item.fee | currency }}</td>
              <td class="text-right">{{ parseFloat(item.fee) + parseFloat(item.amount) | currency }}</td>
              <!-- <td class="text-xs-right">
                <div v-if="!item.metadata || !item.metadata.shipping || !item.metadata.shipping.trackingNumber">
                  <v-chip small label>Not Shipped</v-chip>
                </div>
                <div v-else> {{ item.metadata.shipping.selectedCompany }}:
                  {{ item.metadata.shipping.trackingNumber }} </div>
              </td> -->
              <!-- <td class="text-right small-td">
                {{ item.grandTotal | currency }}
              </td> -->
              <td>
                <v-chip class="text-capitalize" small label dark :color="color(item.status)">
                  {{ item.status }}
                </v-chip>
                <!-- <v-spacer></v-spacer>
                <v-chip class="text-capitalize" @click="openBillPaymentReceipt(item)" small label dark color="yellow">
                  View Bill Payment
                </v-chip> -->
              </td>
            </tr>

          </template>
        </v-data-table>
      </v-card-text>

    </v-card>
    <!-- <v-dialog v-model="viewBillPaymentDialog" fullscreen transition="false">
        <BillPaymentReceipt v-if="viewBillPaymentModal" :showTitle="true" :invoice="viewBillPaymentModal" actions="true" offsety="120" @close="closeBillPaymentReceipt"></BillPaymentReceipt>
      </v-dialog> -->
  </v-container>
</template>
<script>
import historyService from '@/modules/History/service.js';
import DateTimePicker from '@/components/DateTimePicker';
import DateSelectorView from '@/components/DateSelectorView.vue';
// import BillPaymentReceipt from '@/modules/Receipts/Invoice.vue'

export default {
  name: 'sim-history',
  props: ['hideHeader', 'hideCreate', 'defaultRange'],
  components: {
    DateTimePicker,
    DateSelectorView,
    // BillPaymentReceipt
  },
  data() {
    return {
      viewBillPaymentModal: null,
      viewBillPaymentDialog: false,
      start: this.$moment().startOf('day'),
      end: this.$moment().endOf('day'),
      dateTime: {
        start: this.$moment().startOf('day').toISOString(),
        end: this.$moment().endOf('day').toISOString(),
      },
      activationDialog: false,
      selectedSerial: null,
      edit: false,
      status: 0,
      headers: [
        {
          text: 'Date',
          align: 'left',
          value: 'createdAt',
        },
        {
          text: 'Phone Number',
          sortable: true,
          value: 'phoneNumber',
        },
        {
          text: 'Carrier',
          sortable: true,
          value: 'Carrier.name',
        },
        {
          text: 'Amount',
          sortable: true,
          align: 'right',
          value: 'amount',
        },
        {
          text: 'Fee',
          align: 'right',
          sortable: true,
          value: 'fee',
        },
        {
          text: 'Total',
          align: 'right',
          value: 'grandTotal',
        },
        {
          text: "Status",
          value: "status",
          sortable: false,
        },

      ],
      search: null,
      billPayment: [],
      count: 0,
      loading: false,
      contact: JSON.parse(localStorage.getItem(
        `${process.env.VUE_APP_STORAGE_PREFIX}contact`
      ))

    };
  },
  computed: {
    getStart() {
      if (this.defaultRange) return null
      return this.start
    },
    getEnd() {
      if (this.defaultRange) return null
      return this.end
    },
  },
  created() {
    this.getList();

  },
  watch: {
    search() {
      if (this.search.length > 3 || this.search.length === 0) {
        this.getList();
      }
    },
    searchInDate() {
      this.getList();
    },
  },
  methods: {
    color(val) {
      let value = null
      switch (val) {
        case 'paid':
          value = 'green'
          break;
        case 'pending':
          value = 'yellow accent-4'
          break;
        case 'open':
          value = 'red'
          break;
        case 'closed':
          value = 'green'
          break;
        case 'rejected':
          value = 'red accent-4'
          break;
        // default:
        // value = 'grey'
        // 	break;
      }
      // console.log('value', value, val)
      return value
    },
    openBillPaymentReceipt(item) {
      this.viewBillPaymentModal = item
      this.viewBillPaymentDialog = true

    },
    closeBillPaymentReceipt() {
      this.viewBillPaymentDialog = false
      this.viewBillPaymentModal = null
    },
    getList() {
      this.loading = true;
      console.log('this.search is :', this.search)
      let start = this.dateTime.start;
      let end = this.dateTime.end;
      return historyService
        .getBillPayments({
          start,
          end,
          limit: 500,
          search: this.search,
          orderBy: ['id', true],
          contactId: this.contact.id,
          cellsimPortalList: true
        })
        .then(response => {
          console.log('response is :', response)
          this.billPayment = response.data;
          this.count = response.count;
          this.loading = false;
          return response;
        })
        .catch(error => {
          console.log('error is :', error)
        })
    },
  },
  sockets: {
    INVOICECREATED(data) {
      console.log('data is :', data)
      console.log('INVOICECREATED data', data)
      if (data.ContactId && data.ContactId == this.AssignedContactId) {

      }
      this.getList()
      // this.getList()
    },
    INVOICEUPDATED(data) {
      console.log('INVOICEUPDATED data', data)
      if (data.ContactId && data.ContactId == this.AssignedContactId)
        this.getList()
    },
    INVOICEDELETED(data) {
      console.log('INVOICEDELETED data', data)
      if (data.ContactId && data.ContactId == this.AssignedContactId)
        this.getList()
    }

  },
};
</script>

